//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const AUTHENTICATE_REDIRECT = 'authenticate_redirect';
export const AUTHENTICATE_REDIRECTED = 'authenticate_redirected';
export const SESSION_TIMED_OUT = 'session-timed-out';
export const SIGNIN_USER_ERROR = 'signin_user_error';

//// ERRORS CONST
export const ERROR_OTHERS = 'error-others';
export const CLEAN_ERROR = 'error-clean';


//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

// Units

export const FETCH_USER_UNITS_START = 'fetch-user-units-start';
export const UNITS_DATA = 'units-data';

// Tasks

export const FETCH_USER_CURRENT_TASK_START = 'fetch-user-current-task-start';
export const CURRENT_TASK_DATA = "current-task-data";
export const CURRENT_TASK_CURRENT_TIME = "current-task-current-time";
export const CURRENT_TASK_RUNNING_TIME = "current-task-running-time";

export const FETCH_USER_AFFECTED_TASKS_START = "feth-user-affected-tasjs-start";
export const USER_AFFECTED_TASKS_DATA = "user-affected-tasks-data";

export const END_TASK_START = "end-task-start";
export const END_TASK_SUCCESS = "end-s-task-success";

export const ADD_COMMON_TASK_START ="add-common-task-start";
export const ADD_COMMON_TASK_SUCCESS = "add-common-task-success";

export const RESET_CURRENT_TASK_START = "reset-current-task-start";
export const RESET_CURRENT_TASK_SUCCESS = "reset-current-task-success";

export const FETCH_TASKS_ORDER_START = "fetch-tasks-roder-start";
export const TASKS_ORDER_DATA = "tasks-order-data";

export const FETCH_MANAGER_TASKS_START = "fetch-manager-tasks-start";
export const MANAGER_TASKS_DATA = "manager-tasks-data";

export const SWAP_TASK_START = "swap-task-start";
export const SWAP_TASK_SUCCESS = "swap-task-success";

export const SET_OFFICER_PREFERED_TASKS_FOR_UNIT = "set-officer-prefered-tasks-for-unit";

// Issues

export const FETCH_UNIT_ISSUES_START = "fetch-unit-issues-start";
export const ISSUES_DATA = "issues-data";

export const ADD_COMMON_TASK_ISSUE_START = "add-common-task-issue-start";
export const ADD_COMMON_TASK_ISSUE_SUCCESS = "add-common-task-issue-success";

// Coachings

export const FETCH_UNIT_COACHINGS_START = "fetch-unit-coachings-start";
export const COACHINGS_DATA = "coachings-data";

export const ADD_COMMON_TASK_COACHING_START = "add-common-task-coaching-start";
export const ADD_COMMON_TASK_COACHING_SUCCESS = "add-common-task-coaching-success";