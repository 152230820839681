import {
    FETCH_UNIT_ISSUES_START,
    ISSUES_DATA
  } from "../../constants/ActionsTypes";
  
  const INIT_STATE = {
    issues: [],
    issuesLoaded: false,
  };
  
  const Issues = (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case FETCH_UNIT_ISSUES_START: {
        return {
          ...state,
          issues: [],
          issuesLoaded: false,
        };        
      }
  
      case ISSUES_DATA: {
        return {
          ...state,
          issues: action.payload === undefined ? [] : action.payload,
          issuesLoaded: true
        };
      }
  
      default:
        return state;
    }
  }
  
  export default Issues