import {
    FETCH_USER_UNITS_START,
    UNITS_DATA
  } from "../../constants/ActionsTypes";
  
  const INIT_STATE = {
    userUnits: [],
    userUnitsLoaded : false
  };
  
  const Units = (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case FETCH_USER_UNITS_START: {
        return {
          ...state,
          userUnitsLoaded: false
        };        
      }
  
      case UNITS_DATA: {
        return {
          ...state,
          userUnits: action.payload === undefined ? [] : action.payload,
          userUnitsLoaded: true
        };
      }
  
      default:
        return state;
    }
  }
  
  export default Units