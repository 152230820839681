import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IntlMessages from 'util/IntlMessages';
import { ToastContainer } from 'react-toastify';
import { userSignIn } from '../../../redux/actions/Auth';
import { isTokenValid } from '../../../redux/actions/Auth';
import { useNavigate } from 'react-router-dom';

const SignIn = (props) => {

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const validToken = useSelector(({ auth }) => auth.isValidToken);
  const authUser = useSelector(({ auth }) => auth.authUser);
  //const { loading } = useSelector(({ commonData }) => commonData);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        var email = emailInputRef.current.value;
        var password = passwordInputRef.current.value;
        dispatch(userSignIn({ email, password }));
      }
    };
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [dispatch]);


  useEffect(() => {
    if (token !== null) {
      if (validToken) {
        if (authUser.isPasswordReset) {
          navigate('/PasswordReset');
        } else {
          navigate('/');
        }
      } else {
        dispatch(isTokenValid(token));
      }
    }
  }, [token, validToken, dispatch, navigate, authUser]);  


  const onFormSubmit = e => {
    e.preventDefault();
    var email = emailInputRef.current.value;
    var password = passwordInputRef.current.value;
    dispatch(userSignIn({ email, password }));
  }

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

        {/* <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg " to="/" title="Extime">
            EXTIME
            { <img src={require("assets/images/DIGITALIS-EXTIME-LOGOTYPE-2021-13.png")} alt="Extime" title="Extime" /> }
          </Link>
        </div> */}

        <div className="app-login-content">
          {/* <div className="app-login-header mb-4">
            <h1><IntlMessages id="appModule.email" /></h1>
          </div> */}

          <div className="app-login-form">
            <form onSubmit={onFormSubmit}>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  margin="normal"
                  className="mt-1 my-sm-3"
                  required={true}
                  inputRef={emailInputRef}
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  margin="normal"
                  className="mt-1 my-sm-3"
                  required={true}
                  inputRef={passwordInputRef}
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button variant="contained" color="primary" type="submit">
                    <IntlMessages id="appModule.signIn" />
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      
      <ToastContainer />
    </div>
  );
};


export default SignIn;
