import {
    FETCH_USER_CURRENT_TASK_START,
    CURRENT_TASK_DATA,
    CURRENT_TASK_CURRENT_TIME,
    CURRENT_TASK_RUNNING_TIME,
    FETCH_USER_AFFECTED_TASKS_START,
    USER_AFFECTED_TASKS_DATA,
    FETCH_TASKS_ORDER_START,
    TASKS_ORDER_DATA,
    FETCH_MANAGER_TASKS_START,
    MANAGER_TASKS_DATA,
  } from "../../constants/ActionsTypes";
  
  const INIT_STATE = {
    currentTasks: [],
    currentTasksLoaded: false,
    currentTime : 0,
    runningTime : 0,
    tasks : [],
    tasksLoaded : false,
    tasksOrder : [],
    tasksOrderLoaded : false,
    managerTasks : [],
    managerTasksLoaded : false,
  };
  
  const Tasks = (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case FETCH_USER_CURRENT_TASK_START: {
        return {
          ...state,
          currentTasksLoaded: false,
          currentTime : 0,
          runningTime : 0,
        };        
      }
  
      case CURRENT_TASK_DATA: {
        return {
          ...state,
          currentTasks: action.payload === undefined ? [] : action.payload,
          currentTasksLoaded: true
        };
      }

      case CURRENT_TASK_CURRENT_TIME: {
        return {
          ...state,
          currentTime: action.payload === undefined ? [] : action.payload,
        };
      }

      case CURRENT_TASK_RUNNING_TIME: {
        return {
          ...state,
          runningTime: action.payload === undefined ? [] : action.payload,
        };
      }

      case FETCH_USER_AFFECTED_TASKS_START: {
        return {
          ...state,
          tasksLoaded: false,
        };
      }

      case USER_AFFECTED_TASKS_DATA: {
        return {
          ...state,
          tasks: action.payload === undefined ? [] : action.payload,
          tasksLoaded : true
        };
      }

      case FETCH_TASKS_ORDER_START: {
        return {
          ...state,
          tasksOrderLoaded : false
        };
      }

      case TASKS_ORDER_DATA: {
        return {
          ...state,
          tasksOrder : action.payload === undefined ? [] : action.payload,
          tasksOrderLoaded : true
        };
      }

      case FETCH_MANAGER_TASKS_START: {
        return {
          ...state,
          managerTasksLoaded: false,
        };
      }

      case MANAGER_TASKS_DATA: {
        return {
          ...state,
          managerTasks: action.payload === undefined ? [] : action.payload,
          managerTasksLoaded : true
        };
      }
  
      default:
        return state;
    }
  }
  
  export default Tasks