
import React from 'react';
import { useState, useEffect } from 'react';

import './Timer.css'

const Timer = (props) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);  
  const [pausedTime, setPausedTime] = useState(0);
  const [startTime, setStartTime] = useState(new Date());



  useEffect(() => {
    let interval;  
    
      interval = setInterval(() => {
        if (!props.isPaused) {

          const currentTime = new Date();
          const elapsedTimeInSeconds = Math.floor((currentTime - startTime + props.time * 1000 - pausedTime * 1000) / 1000);
          setDays(Math.floor(elapsedTimeInSeconds / (24 * 60 * 60)));
          setHours(Math.floor((elapsedTimeInSeconds / (60 * 60)) % 24));
          setMinutes(Math.floor((elapsedTimeInSeconds / 60) % 60));
          setSeconds(Math.floor(elapsedTimeInSeconds % 60));          
        }
        else {
          setPausedTime(prevPausedTime => prevPausedTime + 1);
        }
      }, 1000);

    
    
    return () => clearInterval(interval);
  }, [props.time, props.isPaused]);



  return (
    <div style={props.style}>
      <div className="timer">
        {days > 0 ? days + " jours " : ""}{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </div>
    </div>
  );
};

export default Timer;