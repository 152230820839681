import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import configureStore from './redux/store';
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { addCommonTask } from "redux/actions/Tasks";
import { LOGOFF_COMMON_TASK_VALUE } from "constants/ConstantTypesValues";

export const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

// navigator.serviceWorker.addEventListener('message', (event) => {
//   if (event.data.type ===  'USER_LEFT') {
//     store.dispatch(addCommonTask(
//       {commonTaskValue : COACHING_COMMON_TASK_VALUE} 
//       ));
//   }
// });

// window.addEventListener('beforeunload', () => { 
//   console.log("BEFORE UNLOAD_SERVICEWORKER")
//   store.dispatch(addCommonTask(
//     {commonTaskValue : LOGOFF_COMMON_TASK_VALUE} 
//     ));  
// });

// window.addEventListener('resize', function(){
//   let fixedWidth = 100;
//   window.resizeTo(fixedWidth, window.screen.availHeight);
// });

// document.addEventListener("visibilitychange", function() {
//   if (document.visibilityState === "hidden") {
//     console.log("BEFORE UNLOAD_VISIBILITYCCHANGE")
//       store.dispatch(addCommonTask(
//     {commonTaskValue : LOGOFF_COMMON_TASK_VALUE} 
//     ));  
//   }
// });
