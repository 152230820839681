import { Chip, CircularProgress, MenuItem, Paper,  Stack, TextField } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'util/Api';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';

import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import IconButton from '@mui/material/IconButton';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';

import { fetchUserUnits } from 'redux/actions/Units';
import { fetchCurrentTask, fetchTasks, endTask, addCommonTask, resetCurrentTask, fetchTasksOrder, swapTask, setofficerpreferedtasksforunit } from 'redux/actions/Tasks';
import { userSignOut } from 'redux/actions/Auth';
import TasksList from './TasksList';

import { LOGOFF_COMMON_TASK_VALUE,PAUSE_COMMON_TASK_VALUE, ISSUE_COMMON_TASK_VALUE, COACHING_COMMON_TASK_VALUE, UNPAUSE_COMMON_TASK_VALUE } from 'constants/ConstantTypesValues';

import './Home.css';
import PastTasksBox from './PastTasksBox';
import Timer from 'util/Timer';
import Link from '@mui/material/Link';
import { addcommontaskIssue, fetchIssues } from 'redux/actions/Issues';
import { addcommontaskCoaching, fetchCoachings } from 'redux/actions/Coachings';
import CoachingDialog from './CoachingDialog';
import IssueDialog from './IssueDialog';
import OrderTasksDialog from './OrderTasksDialog';


const Home = () => {

    //const EXTIME_URL = process.env.NODE_ENV === 'production' ? process.env.EXTIME_WEB_URL : "http://localhost:3001/"
    const EXTIME_URL = "https://andonline.lean4-0.be/";
    //const EXTIME_URL = "https://staging.lean4-0.be/"

    const boldStyle = {
        fontWeight: 'bold',
      };


    const dispatch = useDispatch();

    const token = useSelector(({ auth }) => auth.token);
    const { userUnitsLoaded, userUnits } = useSelector(({ units }) => units);
    const { currentTasksLoaded, currentTasks ,currentTime, tasks, tasksLoaded, tasksOrder, tasksOrderLoaded } = useSelector(({ tasks }) => tasks)
    const { issues, issuesLoaded } = useSelector(({issues}) => issues)
    const { coachings, coachingsLoaded } = useSelector(({coachings}) => coachings)

    const [unit, setUnit] = useState(userUnits.length > 0 ? userUnits[0] : null);
    const [timerTime, setTimerTime] = useState(currentTime? currentTime : 0);
    const [timerTime2, setTimerTime2] = useState(0);
    const [key, setKey] = useState(0)
    const [key2, setKey2] = useState(1000);

    const [pauseTimerTime,setPauseTimerTime] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [isPaused2, setIsPaused2] = useState(true);
    const [runningTimer, setRunningTimer] = useState(1);

    const [pauseTimerKey, setPauseTimerKey] = useState(2000);

    const [onPause, setOnPause] = useState(false); // isPaused = anything that set the timer on pause (pause, issue, coaching); onPause (only on pause, not issue nor coaching)
    const [onIssue, setOnIssue] = useState(false);
    const [onChoaching, setOnChoaching] = useState(false);

    const [sortedTasks, setSortedTasks] = useState([]);

    const [issueDialogOpen, setIssueDialogOpen] = useState(false);
    const [coachingDialogOpen, setCoachingDialogOpen] = useState(false);
    const [orderTasksDialogOpen, setOrderTasksDialogOpen] = useState(false);

   
    
    const sortTasks = () => {
        const newSortedTasks = tasks.sort((a, b) => {
            return tasksOrder.indexOf(a.taskId) - tasksOrder.indexOf(b.taskId);
        });

        setSortedTasks(newSortedTasks)          
    }

    const openIssueDialog = () => {
        setIssueDialogOpen(true);
      };
    
    const handleClickValidateIssues = (issues, comment) => {
        setIssueDialogOpen(false);
        const issueList = issues.map((issue) => {
            return {
              issueId: issue.issueId,
              comment: comment,
            };
          });    
        dispatch(addcommontaskIssue({issues:issueList}));
    };

    const openCoachingDialog = () => {
        setCoachingDialogOpen(true);
      };
      
    const handleClickValidateCoachings = (coachings, comment) => {
        setCoachingDialogOpen(false);
        const coachingList = coachings.map((coaching) => {
            return {
              coachingId: coaching.coachingId,
              comment: comment,
            };
          });  
        dispatch(addcommontaskCoaching({coachings:coachingList}));
    };

    const handleOpenOrderTasksDialog = () => {
        setOrderTasksDialogOpen(true);
      };
    
      const handleClickValidateOrderTasks = (orderedTasks) => {
        const taskIds = orderedTasks.map((task) => task.taskId);
        const taskIdsString = taskIds.join(' ');
        setOrderTasksDialogOpen(false);

        dispatch(setofficerpreferedtasksforunit(unit.unitId, taskIdsString));
        dispatch(fetchTasks(unit.unitId))
        dispatch(fetchTasksOrder(unit.unitId));
      };
    

    const handleChangeUnit = (event) => {
        const selectedUnit = userUnits.find(unit => unit.unitId === event.target.value);
        setUnit(selectedUnit);
        dispatch(fetchTasks(event.target.value))
        dispatch(fetchTasksOrder(event.target.value));
    };

    const handleSwapTask = () => {
        if (runningTimer === 1) {
            setRunningTimer(2);
            setIsPaused(true);
            setIsPaused2(false);
        } else if (runningTimer === 2) {
            setRunningTimer(1);
            setIsPaused(false);
            setIsPaused2(true);
        }

        dispatch(swapTask());
    }

    const handleClickTask = (task) => {
        if (runningTimer === 1) {
            setKey(key+1); // Trick to force the Timer to reupdate
            setTimerTime(0);
        } else if (runningTimer === 2) {
            //setTimerTime(timerTime2)
            setRunningTimer(1);
            setIsPaused(false);
            setIsPaused2(true);
            setKey2(key2+1);
            setTimerTime2(0);            
        }
        
        dispatch(endTask(task))
        dispatch(fetchCurrentTask(token))
    }

    const handlePauseTimer = () => {

        if (runningTimer === 1) {
            setIsPaused(!isPaused);   
        } else if (runningTimer === 2) {
            setIsPaused2(!isPaused2);
        }

        setPauseTimerTime(0);
        setPauseTimerKey(pauseTimerKey + 1); 
    }

    const handleClickPause = () => {  
        setOnPause(!onPause)
        if (runningTimer === 1) {
            if (!isPaused){            
                dispatch(addCommonTask(
                    {commonTaskValue : PAUSE_COMMON_TASK_VALUE} 
                    ));
            } else {
                dispatch(addCommonTask(
                    {commonTaskValue : UNPAUSE_COMMON_TASK_VALUE}              
                ));
            }
        } else if (runningTimer === 2) {
            if (!isPaused2){            
                dispatch(addCommonTask(
                    {commonTaskValue : PAUSE_COMMON_TASK_VALUE} 
                    ));
            } else {
                dispatch(addCommonTask(
                    {commonTaskValue : UNPAUSE_COMMON_TASK_VALUE}              
                ));
            }
        }
        
        handlePauseTimer()
    }

    const handleClickIssue = () => {

        if (!onIssue)
        {
            dispatch(addCommonTask(
                {commonTaskValue :ISSUE_COMMON_TASK_VALUE} 
                ));
        } else {
            dispatch(addCommonTask(
                {commonTaskValue : UNPAUSE_COMMON_TASK_VALUE}              
            ));
            openIssueDialog()
        }
       
        setOnIssue(!onIssue);
    }

    const handleClickSupport = () => {
        setOnChoaching(!onChoaching);
        if(runningTimer === 1) {
            if (!isPaused){
                dispatch(addCommonTask(
                    {commonTaskValue : COACHING_COMMON_TASK_VALUE} 
                    ));
            } else {
                dispatch(addCommonTask(
                    {commonTaskValue : UNPAUSE_COMMON_TASK_VALUE}              
                ));
                openCoachingDialog()
            }
        } else if (runningTimer === 2) {
            if (!isPaused2){
                dispatch(addCommonTask(
                    {commonTaskValue : COACHING_COMMON_TASK_VALUE} 
                    ));
            } else {
                dispatch(addCommonTask(
                    {commonTaskValue : UNPAUSE_COMMON_TASK_VALUE}              
                ));
                openCoachingDialog()
            }
        }
        
        handlePauseTimer()
    }

    const handleClickReset = () => {
        if (runningTimer === 1) {
            setKey(key+1); // Trick to force the Timer to reupdate
            setTimerTime(0);
        }
        else if (runningTimer === 2){
            setKey2(key2+1);
            setTimerTime2(0);
        }
        dispatch(resetCurrentTask())
    }

    const handleLogOffButtonClick = () => {
        dispatch(addCommonTask(
            {commonTaskValue : LOGOFF_COMMON_TASK_VALUE}              
        ));
        dispatch(userSignOut());
    }

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        }
        if (!userUnitsLoaded) {
            dispatch(fetchUserUnits(token))
        } else {
            if (userUnits.length > 0 && !unit) {
                setUnit(userUnits[0])
            }
        }
        if (!currentTasksLoaded) {            
            dispatch(fetchCurrentTask(token))
        } else {
            setTimerTime(currentTime)
        }
        if (unit && !tasksLoaded) {
            dispatch(fetchTasks(unit.unitId))
        }
        if (unit && !tasksOrderLoaded) {
            dispatch(fetchTasksOrder(unit.unitId));
        }
        if (tasksOrderLoaded && tasksLoaded){
            sortTasks()
        }
        if (unit && !issuesLoaded) {
            dispatch(fetchIssues(unit.unitId))
        }
        if (unit && !coachingsLoaded) {
            dispatch(fetchCoachings(unit.unitId))
        }
        //console.log("current task", currentTasks)
        //console.log("userUnits : ", userUnits)
        // console.log("issues", issues)
        // console.log("coachings", coachings)
        //console.log("taskOrder", tasksOrder)

    }, [dispatch, token, tasksOrderLoaded,  unit, userUnitsLoaded, tasksLoaded, currentTasksLoaded]);

    return (
        <div className='container'>
            <Paper className='paper' elevation={1}>
                <IssueDialog 
                    open={issueDialogOpen} 
                    handleClickValidateIssues={handleClickValidateIssues} 
                    issues={issues}
                />
                <CoachingDialog
                    open={coachingDialogOpen}
                    handleClickValidateCoachings={handleClickValidateCoachings}
                    coachings={coachings}
                />
                { tasksLoaded && tasksOrderLoaded ? 
                <OrderTasksDialog
                        open={orderTasksDialogOpen}
                        key={JSON.stringify(sortedTasks)}
                        tasks={sortedTasks}
                        initialOrder={tasksOrder}
                        handleClickValidateOrderTasks={handleClickValidateOrderTasks}
                    />
                    : <div></div>
                }
                <div className="timer-container">
                    <div className="button-container">
                        <Tooltip title="Swap Task">
                            <IconButton onClick={handleSwapTask}>
                                <SwapVerticalCircleIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="timers-wrapper">
                        <div className="timer-wrapper">
                            <Timer key={key} time={timerTime} isPaused={isPaused} style={runningTimer === 1 ? boldStyle : null}/> 
                        </div>
                        <div className="timer-wrapper">
                        <Timer key={key2} time={timerTime2} isPaused={isPaused2} style={runningTimer === 2 ? boldStyle : null}/>
                        </div>
                    </div>

                </div>

                {((isPaused && runningTimer === 1) || (isPaused2 && runningTimer === 2))  ?<div className={"pauseTimer"}> <Timer key={pauseTimerKey} time={pauseTimerTime} isPaused={!isPaused} /> </div>: <div></div>}

                {userUnitsLoaded && currentTasksLoaded && tasksOrderLoaded ?
                    <div className='subcontainer'>
                        <TextField
                            style={{maxWidth:150}}
                            value={unit ? unit.unitId : ''}
                            onChange={handleChangeUnit}
                            select // tell TextField to render select
                            label="Cellule"
                            size="small"
                            >
                            {userUnits.map((unit) => (
                                    <MenuItem key={unit.unitId} value={unit.unitId}>{unit.name}</MenuItem>
                                ))}
                        </TextField>


                            <div style={{ marginBottom: '16px' }}>
                                {tasksLoaded ? <TasksList tasks={sortedTasks} handleClickTask={handleClickTask} disabled={onPause || onChoaching || onIssue} /> : <CircularProgress />}
                            </div>
                            <div className='button-stack'>
                                <Stack spacing={1} >
                                    <Chip 
                                        key={'buttonPause'}
                                        label={"Pause"}
                                        onClick={handleClickPause}
                                        variant="filled"
                                        disabled={onChoaching || onIssue}
                                        sx={{cursor: 'pointer', width:'100%', backgroundColor:'#01579b', color:'#ede7f6'}}
                                        />
                                    <Chip 
                                        key={'buttonIssue'}
                                        label={"Probleme"}
                                        onClick={handleClickIssue}
                                        variant="filled"
                                        disabled={onChoaching || onPause}
                                        sx={{cursor: 'pointer', width:'100%', backgroundColor:'#ef6c00', color:'#ede7f6'}}
                                        />
                                    <Chip 
                                        key={'buttonSupport'}
                                        label={"Support"}
                                        onClick={handleClickSupport}
                                        variant="filled"
                                        disabled={onPause || onIssue}
                                        sx={{cursor: 'pointer', width:'100%', backgroundColor:'#673ab7', color:'#ede7f6'}}
                                        />
                                    <Chip 
                                        key={'buttonReset'}
                                        label={"Reset"}
                                        onClick={handleClickReset}
                                        variant="filled"
                                        disabled={onPause || onIssue || onChoaching}
                                        sx={{cursor: 'pointer', width:'100%', backgroundColor:'#757575', color:'#ede7f6'}}
                                        />
                                </Stack>
                            </div>
                            <div className='pastTasksBox'>
                                <div >
                                    <PastTasksBox pastTasks={currentTasks}/>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div>                                    
                                        <Tooltip title="Réorganiser les taches">
                                            <IconButton onClick={handleOpenOrderTasksDialog}>
                                                <ImportExportIcon/>
                                            </IconButton>
                                        </Tooltip>
                                </div>
                                <div >
                                    <Tooltip title="Log off">
                                        <IconButton onClick={handleLogOffButtonClick}>
                                            <LogoutIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div>                                    
                                        <Tooltip title="Accéder à l'application Web">
                                            <IconButton>
                                                <Link href={EXTIME_URL + "authenticate?token=" + token} target="_blank" rel="noopener noreferrer">
                                                    <DashboardIcon/>
                                                </Link>
                                            </IconButton>
                                        </Tooltip>
                                </div>
                                
                            </div>
                    </div>
                :
                <div className='container'>
                    <Paper className='paper' elevation={1}>
                        <div className="circular-progress-container">
                            <CircularProgress />
                        </div>
                    </Paper>
                </div>}
            </Paper>
        </div>
        

        
    );
};


export default Home;