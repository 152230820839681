import {
    FETCH_ERROR,
    FETCH_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS,

    FETCH_USER_UNITS_START,
    UNITS_DATA
} from "../../constants/ActionsTypes";
import axios from 'util/Api';

export const fetchUserUnits = (token) => {
    return (dispatch) => {
        dispatch({ type: FETCH_USER_UNITS_START });
        axios.get('/getunitsforuser',{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
            .then(({ data }) => {
                console.log(data)
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: UNITS_DATA, payload: data.result.units });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};
